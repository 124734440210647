/*
 * @Author: chenzhuo.li
 * @Date: 2024/10/31 9:19
 * @Description:  折扣优惠
 */
export const discountOptions = [{
  key: '1340010', value: '合作伙伴', relationType: 2
}, {
  key: '1340011', value: '熟客', relationType: 4
}, {
  key: '1340012', value: '店员亲友消费', relationType: 1
}, {
  key: '1340013', value: '本店员工消费', relationType: 3
}, {
  key: '1340015', value: '任意顾客消费', relationType: 5
}]

export const staticRequiredFieldList = [
  { name: '合作伙伴姓名', fieldName: 'partnerName', discountType: '1340010' },
  { name: '合作伙伴手机号', fieldName: 'partnerMobile', discountType: '1340010' },
  { name: '合作伙伴工作单位', fieldName: 'partnerCompany', discountType: '1340010' },
  { name: '员工亲友姓名', fieldName: 'staffRelativeName', discountType: '1340012' },
  { name: '员工亲友手机号', fieldName: 'staffRelativeMobile', discountType: '1340012' }
]

<template>
  <w-popup v-model="show" position="bottom" :style="{ height: '60%' }" :close-on-click-overlay="false" @click-overlay="close">
    <div class="refund-responsible-person-popup-box">
      <div class="refund-responsible-person-box">
        <div class="refund-responsible-person-header">
          <span class="header-title">选择店员</span>
          <div class="header-search-input">
            <!--            <w-search class="search-input" v-model="queryName" placeholder="搜索姓名" />-->
            <!-- <el-input class="header-input" v-model="queryName" :clearable="true" prefix-icon="el-icon-search"
              size="small" placeholder="搜索姓名"></el-input> -->
          </div>
        </div>
        <div class="refund-responsible-person-body">
          <div
              class="responsible-person-box"
              v-for="(item, index) in responsiblePersonList"
              :key="index"
              @click="changeSelectedPerson(item)"
              v-show="!queryName || (queryName && item.name.indexOf(queryName) >= 0)">
            <div class="responsible-person_name">
              {{ item.name }}
            </div>
            <div class="responsible-person_select">
              <i class="responsible-person-select_icon active iconfont iconxuanzhong" v-if="item.selected === 1 || selectedEmp.userId === item.userId"></i>
              <i class="responsible-person-select_icon iconfont iconweixuanzhong" v-else></i>
            </div>
          </div>
        </div>
        <w-foot-btn :foot-btn-config="footBtn" fixed-bottom @click="footClick"/>
      </div>
    </div>
  </w-popup>
</template>
<script>
import { listStoreUsersApi } from '@/api/order'
import { mapState } from 'vuex'

export default {
  name: 'PaymentDiscountPersonSelectPopup',
  components: {},
  props: {
    show: { type: Boolean, required: true, default: false },
    selectedEmps: { type: Array, required: true, default: null }
  },
  data() {
    return {
      responsiblePersonList: [],
      selectedEmp: {},
      queryName: '',
      footBtn: [
        {
          text: '取消',
          plain: true
        },
        {
          text: '确定'
        }
      ]
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.listStoreUsers()
      }
    }
  },
  computed: {
    ...mapState('order', ['storeId'])
  },
  created() {
    this.listStoreUsers()
  },
  methods: {
    footClick(item, index) {
      if (index === 0) {
        this.close()
      } else {
        this.confirm()
      }
    },
    confirm() {
      const selectedPerson = []
      if (!this.selectedEmp.userId) {
        this.$toast('请选择关联员工')
        return false
      }
      const person = {
        userId: this.selectedEmp.userId,
        name: this.selectedEmp.name
      }
      selectedPerson.push(person)

      this.$emit('onConfirmResponsePersonClick', selectedPerson)
      return false
    },
    close() {
      this.$emit('onCloseClick')
    },
    listStoreUsers() {
      listStoreUsersApi(this.storeId).then(res => {
        console.log(res, 'listStoreUsersApi')
        if (res.status !== 1) {
          this.$toast(res.data.msg)
          return false
        }
        this.responsiblePersonList = res.data

        this.initChosenPerson()
      })
    },
    initChosenPerson() {
      if (this.selectedEmps.length > 0) {
        this.selectedEmp = this.selectedEmps[0]
      } else {
        this.selectedEmp = {}
      }
    },
    changeSelectedPerson(person) {
      this.selectedEmp = person
    }
  }
}
</script>
<style lang="scss" scoped>
.refund-responsible-person-box {
  position: relative;
  width: 100%;
  height: 100%;

  .refund-responsible-person-header {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    padding: 22px 24px;
    align-items: center;
    flex-wrap: nowrap;
    background-color: $color-white;
    border-radius: 16px 16px 0 0;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 24px;
      right: 24px;
      border-top: 1px solid $color-border;
    }

    .header-title {
      flex: 1;
      line-height: 36px;
      font-size: 30px;
      font-weight: bold;
      color: $color-text-main;
      white-space: nowrap;
    }
  }

  .refund-responsible-person-body {
    width: 100%;
    height: 100%;
    padding: 100px 0 100px 0;
    overflow-y: auto;
  }
}

.responsible-person-box {
  position: relative;
  display: flex;
  width: 100%;
  padding: 0 24px;
  align-items: center;
  height: 88px;

  .responsible-person_name {
    flex: 1;
    line-height: 36px;
    font-size: 28px;
    color: $color-text-main;
    font-weight: 400;
  }

  .responsible-person_select {
    flex: 0;
    color: $color-text-disabled;

    &.active {
      color: $color-primary;
    }

    i {
      font-size: 40px;
      vertical-align: bottom;
    }

    .responsible-person-select_icon {
      font-size: 40px;
      color: $color-text-disabled;

      &.active {
        color: $color-primary;
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 24px;
    right: 24px;
    border-top: 1px solid $color-border;
  }
}

/deep/ .van-search {
  width: 350px;
  padding-right: 0;
}

/deep/ .van-search__content {
  border-radius: 28px;
}

//.search-input {
//  /deep/.van-field__body {
//    -webkit-user-select: text !important;
//  }
//}
</style>

·
<!--
 * @Author: chenzhuo.li
 * @Date: 2024/10/30 15:26
 * @Description: 扣优惠原因弹窗
 -->
<template>
  <w-popup v-model="popupShow" :style="{ height: '90%' }" position="bottom" @close="onClose">
    <div class="wh-popup-container">
      <div class="wh-popup-header">
        <div class="wh-popup-header_title">特定顾客折扣优惠原因</div>
      </div>
      <div class="wh-popup-body with-header with-footer">
        <div class="wh-popup-content">
          <div class="reason-box">
            <div class="discount-reason reason-title" v-if="totalDiscountItems">
              <div class="discount-reason-top">
                <div class="refund-reason_name">
                  公司已设置
                  <span class="color-primary" @click="showItemPopup">{{ totalDiscountItems }}个商品</span>
                  不参与折扣
                </div>
              </div>
            </div>
            <!--          折扣原因-->
            <div v-for="(item, index) in discountReasonList" :key="index"
                 class="discount-reason" @click="changeRefundReason(item)">
              <div class="discount-reason-top">
                <div class="refund-reason_name">
                  {{ item.name }}折扣优惠
                </div>
                <div class="refund-reason_select">
                  <template v-if="item.disabled">
                    <i class="discount-reason-select_icon disabled iconfont icondanxuan-bukexuan"/>
                  </template>
                  <template v-else>
                    <i v-if="selectedDiscountReason.refundType === item.refundType" class="discount-reason-select_icon active iconfont icondanxuan-xuanzhong"/>
                    <i v-else class="discount-reason-select_icon iconfont icondanxuan-weixuanzhong"/>
                  </template>
                </div>
              </div>
              <template v-if="item.enable === 0">
                <div class="discount-reason-bottom">公司暂未开放此折扣
                </div>
              </template>
              <template v-else>
                <div v-if="item.refundType === '1340010'" class="discount-reason-bottom">
                  需填写伙伴的工作单位、姓名、手机号；
                </div>
                <div v-if="item.refundType === '1340011'" class="discount-reason-bottom">
                  仅适用于最近{{ oldCustomDays }}天内到店下单至少{{ oldCustomTimes }}次的顾客；
                  <span v-if="item.disabled">当前订单顾客不符合</span>
                </div>
                <div v-if="item.refundType === '1340012' && item.enable === 1" class="discount-reason-bottom">
                  需选择门店伙伴
                </div>
                <div v-if="item.refundType === '1340013'" class="discount-reason-bottom">
                  <template v-if="item.disabled">可通过【我的-订单-收款】操作“本店员工”消费下单的折扣优惠</template>
                  <template v-else>仅适用本店员工下的订单，员工会员卡的已优惠的商品不可打折</template>
                </div>
                <div v-if="item.refundType === '1340015'" class="discount-reason-bottom">
                  店员无需录入任何顾客信息
                </div>
              </template>
            </div>
          </div>
          <!--        折扣表单-->
          <div class="discount-box">
            <!--          合作伙伴折扣优惠-->
            <template v-if="selectedDiscountReason.refundType==='1340010'">
              <w-cell-field v-model="relationCustomInfo.customName" :label="getRequiredFieldList('1340010','partnerName')?'顾客姓名*':'顾客姓名'" placeholder="请输入顾客姓名" input-align="right"/>
              <w-cell-field v-model="relationCustomInfo.customCompany" :label="getRequiredFieldList('1340010','partnerCompany')?'顾客工作单位*':'顾客工作单位'" placeholder="请输入顾客工作单位" input-align="right" class="left-width-cell"/>
              <w-cell-field v-model="relationCustomInfo.customMobile" :label="getRequiredFieldList('1340010','partnerMobile')?'顾客手机*':'顾客手机'" type="digit" maxlength="11" placeholder="请输入顾客手机号" input-align="right" class="input-cell"/>
              <w-cell title="优惠折扣*" :value="discountFilter(discountRate)" is-select-type select-placeholder="待选择" @click="openDiscountPicker"/>
              <w-cell v-if="discountAmount" title="优惠金额" :value="amountFilter(discountAmount)" value-class="color-danger"/>
            </template>
            <!--          熟客折扣优惠-->
            <template v-if="selectedDiscountReason.refundType==='1340011'">
              <template v-if="payType === '1'">
                <w-cell v-if="customMobileBeforeCheck" title="顾客手机" :value="customMobileBeforeCheck" class="no-padding-left">
                  <template #custom-label>
                    <p class="desc-label">顾客符合熟客标准</p>
                  </template>
                </w-cell>
              </template>
              <template v-else>
                <w-cell-field v-if="!customMobileHasChecked" v-model="customMobileBeforeCheck" type="digit" maxlength="11" label="顾客手机*" placeholder="请输入顾客手机号" input-align="right" class="input-cell" key="1340011customMobileBeforeCheck">
                  <template #button>
                    <div class="submit-button" @click="checkCustomMobile">提交</div>
                  </template>
                </w-cell-field>
                <w-cell v-else title="顾客手机" :value="customMobileBeforeCheck" class="no-padding-left">
                  <template #custom-label>
                    <p class="desc-label">顾客符合熟客标准</p>
                  </template>
                </w-cell>
              </template>
              <w-cell v-if="discountCellShow" title="优惠折扣*" :value="discountFilter(discountRate)" is-select-type select-placeholder="待选择" @click="openDiscountPicker"/>
              <w-cell v-if="discountAmount" title="优惠金额" :value="amountFilter(discountAmount)" value-class="color-danger"/>
            </template>
            <!--          店员亲友消费折扣优惠-->
            <template v-if="selectedDiscountReason.refundType==='1340012'">
              <w-cell title="亲友关系员工*" :value="relationEmpName" is-select-type select-placeholder="待选择" @click="showPersonSelectPopup"/>
              <w-cell-field v-model="relationCustomInfo.customName" :label="getRequiredFieldList('1340012','staffRelativeName')?'顾客姓名*':'顾客姓名'" placeholder="请输入顾客姓名" input-align="right" />
              <w-cell-field v-model="relationCustomInfo.customMobile" :label="getRequiredFieldList('1340012','staffRelativeMobile')?'顾客手机*':'顾客手机'" type="digit" maxlength="11" placeholder="请输入顾客手机号" input-align="right" class="input-cell"/>
              <w-cell title="优惠折扣*" :value="discountFilter(discountRate)" is-select-type select-placeholder="待选择" @click="openDiscountPicker"/>
              <w-cell v-if="discountAmount" title="优惠金额" :value="amountFilter(discountAmount)" value-class="color-danger"/>
            </template>
            <!--          本店员工消费折扣优惠-->
            <template v-if="selectedDiscountReason.refundType==='1340013'">
              <w-cell title="优惠折扣*" :value="discountFilter(discountRate)" is-select-type select-placeholder="待选择" @click="openDiscountPicker"/>
              <w-cell v-if="discountAmount" title="优惠金额" :value="amountFilter(discountAmount)" value-class="color-danger"/>
            </template>
            <!--          任意顾客消费折扣优惠-->
            <template v-if="selectedDiscountReason.refundType==='1340015'">
              <w-cell title="优惠折扣*" :value="discountFilter(discountRate)" is-select-type select-placeholder="待选择" @click="openDiscountPicker"/>
              <w-cell v-if="discountAmount" title="优惠金额" :value="amountFilter(discountAmount)" value-class="color-danger"/>
            </template>
          </div>
        </div>

        <!-- 不打折商品弹窗 -->
        <tree-select-popup :data="discountItems" :editable="false" :rightBtnText="'关闭'" :selectedIds="selectedDiscountItemsIds" :show.sync="itemPopupShow" :showLeftBtn="false" height="80%" title="不参与折扣的商品"/>
        <!-- 折扣力度选择器 -->
        <w-popup v-model="showDiscountPicker" position="bottom" :style="{ height: '50%' }">
          <w-picker show-toolbar title="" :columns="discountPickerColumns" @confirm="confirmSelectDiscount" @cancel="showDiscountPicker = false"/>
        </w-popup>
        <!--        员工选择弹窗-->
        <payment-discount-person-select-popup :show="personSelectPopupShow" :selected-emps="relationCustomInfo.relationEmpList" @onCloseClick="personSelectPopupShow=false" @onConfirmResponsePersonClick="personSelect"/>
        <!--        二次确认弹窗-->
        <dialog-box :show="confirmDialogShow" title="请确定" :showLeftBtn="true" leftBtnText="取消" rightBtnText="确定" @onBackdropClick="confirmDialogShow = false" @onLeftClick="confirmDialogShow = false" @onRightClick="onConfirm">
          <div slot="body">
            <p class="confirm-dialog-text">确定后，系统将抄送“上级”；</p>
          </div>
        </dialog-box>
      </div>
      <div class="wh-popup-footer">
        <w-foot-btn :config-mode="false" left-btn-text="取消" right-btn-text="选好了" @onLeftClick="onClose" @onRightClick="onSubmit"/>
      </div>
    </div>
  </w-popup>
</template>
<script>
import TreeSelectPopup from '@/components/common/TreeSelectPopup.vue'
import {
  calOrderDiscountApi,
  checkCustomMobileApi,
  discountCheckCustomMobileApi,
  getDiscountConfigApi,
  setOrderDiscountApi
} from '@/api/order'
import { mapState } from 'vuex'
import { discountOptions } from '@/model/discount'
import { cloneDeep } from 'lodash-es'
import PaymentDiscountPersonSelectPopup from '@/views/order/PaymentDiscountPersonSelectPopup.vue'
import validate from '@/utils/validate'
import DialogBox from '@/components/common/DialogBox.vue'

export default {
  name: 'PaymentDiscountPopup',
  components: { DialogBox, PaymentDiscountPersonSelectPopup, TreeSelectPopup },
  props: {
    show: { type: Boolean, required: true, default: false },
    orderId: { type: String, required: true, default: '' },
    discountInfo: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    brandCode: { type: String, required: false, default: '' }
  },
  data() {
    return {
      popupShow: false,
      discountReasonList: [],
      selectedDiscountReason: {},
      isStoreHead: false,
      isStoreStaff: false,
      discountItems: [],
      selectedDiscountItemsIds: [],
      itemPopupShow: false,
      discountRate: '',
      showDiscountPicker: false,
      discountPickerColumns: [],
      relationCustomInfo: {
        relationEmpList: [],
        customName: '',
        customCompany: '',
        customMobile: '',
        relationType: 1
      },
      customMobileBeforeCheck: '',
      // 熟客打折手机号是否已经校验
      customMobileHasChecked: false,
      discountAmount: '',
      personSelectPopupShow: false,
      confirmDialogShow: false,
      submitDisabled: false,
      discountConfig: {},
      checkBtnDisabled: false
    }
  },
  watch: {
    show(val, oldVal) {
      this.popupShow = val
      if (val) {
        this.setDiscountColumns()
        this.getDiscountConfig()
      }
    }
  },
  computed: {
    ...mapState('order', ['userId', 'payType']),
    oldCustomDays() {
      if (this.originDiscountReasonList && this.originDiscountReasonList.length) {
        if (this.originDiscountReasonList.find(x => x.refundType === '1340011')) {
          return this.originDiscountReasonList.find(x => x.refundType === '1340011').days
        } else {
          return ''
        }
      }
      return ''
    },
    oldCustomTimes() {
      if (this.originDiscountReasonList && this.originDiscountReasonList.length) {
        if (this.originDiscountReasonList.find(x => x.refundType === '1340011')) {
          return this.originDiscountReasonList.find(x => x.refundType === '1340011').times
        } else {
          return ''
        }
      }
      return ''
    },
    totalDiscountItems() {
      let number = 0
      if (this.discountItems && this.discountItems.length > 0) {
        this.discountItems.forEach(item => {
          item.children.forEach(ele => {
            if (ele.id) {
              number++
            }
          })
        })
      }
      return number
    },
    relationEmpName() {
      if (this.relationCustomInfo.relationEmpList && this.relationCustomInfo.relationEmpList.length) {
        return this.relationCustomInfo.relationEmpList[0].name
      }
      return ''
    },
    discountCellShow() {
      if (this.payType === '1') {
        return true
      } else {
        return this.customMobileHasChecked
      }
    }
  },
  filters: {},
  created() {
    this.getDiscountConfig()
  },
  methods: {
    getDiscountConfig() {
      getDiscountConfigApi(this.orderId, this.userId).then(res => {
        if (res.status !== 0) {
          this.discountReasonList = []
          this.$toast(res.msg)
          return false
        }
        if (res.data) {
          this.discountConfig = res.data
          this.isStoreHead = !!res.data.isStoreHead
          this.isStoreStaff = !!res.data.isStoreStaff
          this.discountItems = res.data.items
          this.originDiscountReasonList = res.data.discountOptions
          this.discountReasonList = this.setDiscountOptions(res.data.discountOptions)
          this.$emit('getDiscountConfig', res.data)
          this.initData()
        }
      })
    },
    setDiscountOptions() {
      let arr = cloneDeep(this.originDiscountReasonList)
      arr.forEach(x => {
        x.disabled = true
        if (discountOptions.find(y => y.key === x.refundType)) {
          x.name = discountOptions.find(y => y.key === x.refundType).value
          x.relationType = discountOptions.find(y => y.key === x.refundType).relationType
        } else {
          x.name = ''
          x.relationType = ''
        }
        // if (x.enable === 1 && this.isStoreHead && x.status === 1) {
        //   x.disabled = false
        // }
        if (x.enable === 1) {
          x.disabled = false
        }
      })
      // 通过二维码收款进买单页，如果当前扫码用户是门店员工，默认勾选本店员工打折，其他选项禁用
      if (this.payType === '1') {
        if (this.isStoreStaff) {
          const staffReason = arr.find(x => x.refundType === '1340013')
          if (staffReason && !staffReason.disabled) {
            arr.forEach(x => {
              if (x.refundType !== '1340013') {
                x.disabled = true
              }
            })
            this.changeRefundReason(staffReason)
          }
        } else {
          arr.forEach(x => {
            if (x.refundType === '1340013') {
              x.disabled = true
            }
          })
        }
        this.customMobileBeforeCheck = this.discountConfig.mobile
        this.customMobileHasChecked = true
      } else {
        // pos-web下单，本店员工消费默认禁用
        arr.forEach(x => {
          if (x.refundType === '1340013') {
            x.disabled = true
          }
        })
      }
      return arr
    },
    initData() {
      if (this.discountInfo) {
        const info = cloneDeep(this.discountInfo)
        this.selectedDiscountReason = this.discountReasonList.find(x => x.relationType === info.relationCustomInfo.relationType)
        this.setDiscountColumns(this.selectedDiscountReason.discount)
        this.discountRate = Number(info.discountRate) * 10
        this.discountAmount = info.discountAmount
        this.relationCustomInfo = info.relationCustomInfo
        if (this.selectedDiscountReason.refundType === '1340011') {
          this.customMobileBeforeCheck = this.relationCustomInfo.customMobile
          this.customMobileHasChecked = true
        }
      }
    },
    openDiscountPicker() {
      if (this.selectedDiscountReason.refundType) {
        this.showDiscountPicker = true
      } else {
        this.$toast('请选择打折原因')
      }
    },
    confirmSelectDiscount(val) {
      this.showDiscountPicker = false
      if (val[1] === '免单') {
        this.discountRate = 0
      } else {
        this.discountRate = parseFloat(val.join('')) * 10
      }
      this.calOrderDiscount()
    },
    calOrderDiscount() {
      const params = {
        orderId: this.orderId,
        userId: this.userId,
        discountRate: this.discountRate / 10
      }
      calOrderDiscountApi(params).then(res => {
        if (res.status !== 0 && res.data) {
          this.discountAmount = ''
          this.$toast(res.msg)
          return false
        }
        this.discountAmount = res.data
      })
    },
    showItemPopup() {
      if (this.discountItems && this.discountItems.length > 0) {
        const arr = []
        if (this.discountItems && this.discountItems.length > 0) {
          this.discountItems.forEach(item => {
            item.children.forEach(ele => {
              if (ele.id) {
                arr.push(ele.id)
              }
            })
          })
        }
        this.selectedDiscountItemsIds = arr
        this.itemPopupShow = true
      }
    },
    onSubmit() {
      if (this.submitDisabled) {
        return false
      }
      if (this.validate()) {
        this.$toast(this.validate())
        return false
      }
      this.confirmDialogShow = true
    },
    onConfirm() {
      this.confirmDialogShow = false
      this.submitDisabled = true
      if (this.selectedDiscountReason.refundType === '1340011') {
        this.relationCustomInfo.customMobile = this.customMobileBeforeCheck
      }
      const params = {
        orderId: this.orderId,
        userId: this.userId,
        discountRate: this.discountRate / 10,
        relationCustomInfo: this.relationCustomInfo
      }
      setOrderDiscountApi(params).then(res => {
        this.submitDisabled = false
        if (res.status !== 0 && res.data) {
          this.$toast(res.msg)
          return false
        }
        this.onClose()
        this.$emit('setOrderDiscount')
      })
    },
    onClose() {
      this.$emit('onClose')
    },
    changeRefundReason(reason) {
      if (reason.disabled) {
        return
      }
      this.selectedDiscountReason = reason
      this.relationCustomInfo = {
        relationEmpList: [],
        customName: '',
        customCompany: '',
        customMobile: '',
        relationType: this.selectedDiscountReason.relationType
      }
      if (this.selectedDiscountReason.refundType === '1340011' && this.relationCustomInfo.customMobile) {
        this.customMobileBeforeCheck = this.relationCustomInfo.customMobile
        this.customMobileHasChecked = true
      }
      this.setDiscountColumns(reason.discount)
      this.discountRate = ''
      this.discountAmount = ''
    },
    setDiscountColumns(maxDiscount) {
      if (maxDiscount) {
        this.discountPickerColumns = []
        const str = String(maxDiscount)
        const arr = []
        for (let i = str[0]; i < 10; i++) {
          arr[i] = {
            text: String(i),
            children: [
              {
                text: '.',
                children: []
              }
            ]
          }
        }
        arr.splice(0, str[0])
        arr.forEach((item, index) => {
          item.children.forEach(ele => {
            if (item.text === str[0]) {
              for (let j = str[2] || 0; j < 10; j++) {
                ele.children.push({
                  text: String(j)
                })
              }
            } else {
              for (let j = 0; j < 10; j++) {
                ele.children.push({
                  text: String(j)
                })
              }
            }
          })
        })
        this.discountPickerColumns = arr
      } else {
        const arr = []
        for (let i = 0; i < 10; i++) {
          arr[i] = {
            text: String(9 - i),
            children: [
              {
                text: '.',
                children: []
              },
              {
                text: '免单',
                children: []
              }
            ]
          }
        }
        arr.forEach(item => {
          item.children.forEach(ele => {
            for (let j = 0; j < 10; j++) {
              ele.children.push({
                text: String(j)
              })
            }
          })
        })
        this.discountPickerColumns = arr
      }
    },
    showPersonSelectPopup() {
      this.personSelectPopupShow = true
    },
    personSelect(val) {
      this.relationCustomInfo.relationEmpList = val
      this.personSelectPopupShow = false
    },
    validate() {
      // 折扣都不可选
      if (this.discountReasonList.every(x => x.disabled)) {
        return '公司暂未开放此功能'
      }
      // 未选择原因
      if (!this.selectedDiscountReason.refundType) {
        return '请选择折扣优惠原因'
      }
      // 合作伙伴折扣优惠
      if (this.selectedDiscountReason.refundType === '1340010') {
        // const requiredFieldList =
        if (!this.relationCustomInfo.customName && this.getRequiredFieldList('1340010', 'partnerName')) {
          return '请输入顾客姓名'
        }
        if (!this.relationCustomInfo.customCompany && this.getRequiredFieldList('1340010', 'partnerCompany')) {
          return '请输入顾客工作单位'
        }
        if (!this.relationCustomInfo.customMobile && this.getRequiredFieldList('1340010', 'partnerMobile')) {
          return '请输入顾客手机号'
        }
        if (!validate.mobile(this.relationCustomInfo.customMobile) && this.getRequiredFieldList('1340010', 'partnerMobile')) {
          return '请输入正确的手机号'
        }
      }
      // 熟客折扣优惠
      if (this.selectedDiscountReason.refundType === '1340011') {
        if (!this.customMobileBeforeCheck && this.payType !== '1') {
          return '请输入顾客手机号'
        }
      }
      // 店员亲友消费折扣优惠
      if (this.selectedDiscountReason.refundType === '1340012') {
        if (!this.relationCustomInfo.relationEmpList || !this.relationCustomInfo.relationEmpList.length) {
          return '请选择亲友关系员工'
        }
        if (!this.relationCustomInfo.customName && this.getRequiredFieldList('1340012', 'staffRelativeName')) {
          return '请输入顾客姓名'
        }
        if (!this.relationCustomInfo.customMobile && this.getRequiredFieldList('1340012', 'staffRelativeMobile')) {
          return '请输入顾客手机号'
        }
        if (!validate.mobile(this.relationCustomInfo.customMobile) && this.getRequiredFieldList('1340012', 'staffRelativeMobile')) {
          return '请输入正确的手机号'
        }
      }
      // 本店员工消费折扣优惠
      if (this.selectedDiscountReason.refundType === '1340013') {
      }
      if (!this.discountRate && this.discountRate !== 0) {
        return '请选择优惠折扣力度'
      }
      return ''
    },
    checkCustomMobile() {
      if (this.checkBtnDisabled) {
        return false
      }
      if (!this.customMobileBeforeCheck) {
        this.$toast('请输入顾客手机号')
        return false
      }
      if (!validate.mobile(this.customMobileBeforeCheck)) {
        this.$toast('请输入正确的手机号')
        return false
      }
      this.checkBtnDisabled = true
      checkCustomMobileApi(this.brandCode, this.customMobileBeforeCheck).then(res => {
        if (res.status === '0') {
          this.checkBtnDisabled = false
          this.$toast('手机号为门店员工电话，请正确填写！')
          return false
        }
        discountCheckCustomMobileApi(this.orderId, this.userId, this.customMobileBeforeCheck).then(res => {
          this.checkBtnDisabled = false
          if (res.status !== 0) {
            this.$toast(res.msg)
            return false
          }
          this.customMobileHasChecked = true
        })
      })
    },
    discountFilter(val) {
      if (!val && val !== 0) {
        return ''
      }
      if (val === 0) {
        return '免单'
      }
      return (val / 10).toFixed(1) + '折'
    },
    amountFilter(val) {
      if (!parseFloat(val)) {
        return ''
      }
      return '￥' + parseFloat(val).toFixed(2)
    },
    getRequiredFieldList(refundType, fieldName) {
      const arr = cloneDeep(this.discountReasonList)
      const requiredFieldList = arr.find(x => x.refundType === refundType).requiredFieldList
      if (requiredFieldList && requiredFieldList.length) {
        return requiredFieldList.includes(fieldName)
      } else {
        return false
      }
    }
  }
}
</script>
<style scoped lang="scss">
.discount-reason {
  position: relative;
  display: flex;
  width: 100%;
  padding: 0 24px;
  align-items: center;
  flex-direction: column;
  background-color: $color-white;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 24px;
    right: 24px;
    border-top: 1px solid $color-border;
  }

  .discount-reason-top {
    width: 100%;
    display: flex;
    padding: 24px 0;

    .refund-reason_name {
      flex: 1;
      line-height: 36px;
      font-size: 30px;
      color: $color-text-main;
      font-weight: 400;
    }

    .refund-reason_desc {
      margin-top: 8px;
      font-size: 24px;
      line-height: 30px;
      color: $color-text-sub;
    }

    .refund-reason_select {
      flex: 0;
      margin-left: 16px;
      color: $color-text-disabled;

      //&.active {
      //  color: $color-primary;
      //}
      //&.disabled{
      //  opacity: 0.6;
      //}

      i {
        font-size: 40px;
        vertical-align: bottom;
      }

      .discount-reason-select_icon {
        font-size: 40px;
        //color: $color-text-disabled;

        &.active {
          color: $color-primary;
        }

        &.disabled {
          opacity: 0.6;
        }
      }
    }
  }

  .discount-reason-bottom {
    width: 100%;
    margin-top: -18px;
    margin-bottom: 24px;
    font-size: 24px;
    color: $color-text-sub;
  }
}

.reason-title {
  background: $color-background;

  .discount-reason-top {
    padding: 20px 0;
  }

  .refund-reason_name {
    font-size: 24px !important;
    color: $color-text-sub !important;
  }
}

#blue-span {
  color: $color-primary !important;
}

.discount-box {
  margin-top: 12px;

  /deep/ .van-cell {
    padding: 16px 24px;
  }

  /deep/ .van-field__label {
    color: $color-text-main;
  }

  .input-cell {
    /deep/ .van-field__control {
      width: 100%;
      border: 0;
      text-align: right;
    }
  }

  .submit-button {
    width: 96px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    line-height: 30px;
    color: $color-primary;
    font-weight: bold;
    border: 1px solid $color-primary;
    border-radius: 8px;
    background: #F7FAFF;
  }

  .desc-label {
    margin: 0;
    font-size: 24px;
    line-height: 32px;
    color: $color-text-sub;
  }

}

.no-padding-left {
  /deep/ .van-cell {
    padding-left: 0;
  }
}
.left-width-cell{
/deep/.van-field__label{
  width: 7em;
}
}
/deep/.van-picker__toolbar{
  height: 80px;
}
</style>
